import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/v-7qCczbsLY">
    <SEO title="We Have a Protector - Counter Culture" />
  </Layout>
)

export default SermonPost
